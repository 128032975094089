<template>
  <div class="grey lighten-2 py-12">
    <v-container>
      <h3 :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h3'"
          class="text-center text-break text-uppercase font-weight-bold primary--text mb-4">
        Our Plans
      </h3>
      <p class="text-center">
        Our range of options are able to suit a range of price points and
        services for you needs. Select one below to get started.
      </p>
    </v-container>
    <v-row class="pa-8">
      <!-- Mobile View -->
      <v-col v-for="tier in tiers" v-if="$vuetify.breakpoint.mobile" :key="tier.title" cols="12" md="4" sm="6">
        <v-card class="pt-2 px-3 pb-4 d-flex flex-column" color="primary" height="100%">
          <v-card class="mt-2 flex">
            <v-card-title class="mb-n4">
              <v-row no-gutters>
                <v-col>
                  <p class="text-break">{{ tier.title }}</p>
                  <p class="primary--text mt-n4">{{ tier.price }}</p>
                  <p class="text-break">Can have up to {{ tier.partsAmount }} parts</p>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <p>{{ tier.desc }}</p>
              <ul>
                <li v-for="benefit in tier.benefits">{{ benefit }}</li>
              </ul>
            </v-card-text>
          </v-card>
          <v-btn v-if="tier.id !== 0" block class="my-3 flex-grow-0"
                 height="50px" @click="$router.push({name: 'SubSummary', params: {planID: tier.id}})">Select plan
          </v-btn>
        </v-card>
      </v-col>
      <!-- Desktop View -->
      <v-col v-for="tier in tiers" v-if="!$vuetify.breakpoint.mobile" :key="tier.title">
        <v-card class="pt-2 px-3 pb-4" color="primary">
          <v-card class="mt-2">
            <v-card-title class="mb-n4">
              <v-row no-gutters>
                <v-col>
                  <p class="text-break">{{ tier.title }}</p>
                  <p class="primary--text mt-n4">{{ tier.price }}</p>
                  <p class="text-break">Can have up to {{ tier.partsAmount }} parts</p>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <p>{{ tier.desc }}</p>
              <ul>
                <li v-for="benefit in tier.benefits">{{ benefit }}</li>
              </ul>
            </v-card-text>
          </v-card>
          <v-btn v-if="tier.id !== currentUser.usersTier" block class="my-3"
                 @click="$router.push({name: 'SubSummary', params: {planID: tier.id}})">Select plan
          </v-btn>
          <v-btn v-else block class="my-3" color="secondary" @click="$router.push({name: 'Account'})">Current Tier</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "PlansView",
  data() {
    return {
      tiers: [
        {
          id: 0,
          title: "Private Seller",
          price: "Free",
          desc: "A private seller is someone who sells infrequently and in very limited amounts.",
          partsAmount: 50,
          benefits: []
        },
        {
          id: 1,
          title: "Micro Business",
          price: "$50/year",
          desc: "A step up from free offering more parts for enthusiasts who have a fair amount of spares around.",
          partsAmount: 200,
          benefits: []
        },
        {
          id: 2,
          title: "Small Business",
          price: "$250/year",
          desc: "Intended for small businesses, this plan offers enough parts for shops wanting to sell frequently and list most of their stock.",
          partsAmount: 500,
          benefits: []
        },
        {
          id: 3,
          title: "Medium Business",
          price: "$500/year",
          desc: "Medium Businesses require listings that really show off their full catalog of stock to their audience.",
          partsAmount: 1000,
          benefits: []
        },
        {
          id: 4,
          title: "Large Business",
          price: "$5,000/year",
          desc: "Intended for large scaling stores that are constantly turning out stock and need to list a wider range of parts.",
          partsAmount: 10000,
          benefits: []
        }
      ]
    }
  },
  computed: {
    ...mapState('user', ['currentUser'])
  }
}
</script>

<style scoped>

</style>