<template>
  <div>
    <PlansView/>
    <WhySubscription/>
<!--    <FAQ />-->
  </div>
</template>

<script>
import FAQ from "@/components/Subscriptions/FAQ.vue";
import WhySubscription from "@/components/Subscriptions/WhySubscription";
import PlansView from "@/components/Subscriptions/PlansView";

export default {
  components: {WhySubscription, FAQ, PlansView},
  metaInfo: {
    title: "Subscriptions",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
};
</script>

<style>

</style>