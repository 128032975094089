<template>
  <div class="secondary pa-8">
    <v-container>
      <h3 :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h3'"
          class="text-center text-break text-uppercase font-weight-bold white--text mb-4">
        Frequently Asked Questions
      </h3>
      <v-expansion-panels>
        <v-expansion-panel v-for="question in questions" :key="question.title">
          <v-expansion-panel-header>
            {{question.title}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{question.answer}}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {
      questions: [
        {
          title: "What is STRIPE Payments?",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do\n" +
              "            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut\n" +
              "            enim ad minim veniam, quis nostrud exercitation ullamco laboris\n" +
              "            nisi ut aliquip ex ea commodo consequat."
        },
        {
          title: "Can I cancel at anytime?",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do\n" +
              "            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut\n" +
              "            enim ad minim veniam, quis nostrud exercitation ullamco laboris\n" +
              "            nisi ut aliquip ex ea commodo consequat."
        },
        {
          title: "Can I pay monthly, quarterly or yearly?",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do\n" +
              "            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut\n" +
              "            enim ad minim veniam, quis nostrud exercitation ullamco laboris\n" +
              "            nisi ut aliquip ex ea commodo consequat."
        },
        {
          title: "Does SellMyCarParts.com.au have a guarantee/warranty?",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do\n" +
              "            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut\n" +
              "            enim ad minim veniam, quis nostrud exercitation ullamco laboris\n" +
              "            nisi ut aliquip ex ea commodo consequat."
        },
        {
          title: "What happens if I downgrade or cancel my plan?",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do\n" +
              "            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut\n" +
              "            enim ad minim veniam, quis nostrud exercitation ullamco laboris\n" +
              "            nisi ut aliquip ex ea commodo consequat."
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>